import React, {useEffect} from 'react';
import Layout from '../components/Layout';
import SimpleHeaderModule from '../components/SimpleHeaderModule';

export default () => {
  useEffect(() => {
    window.location.replace('https://marketplace.zoom.us/apps/4gAucbSMSAi4qaaQPmXatA');
  }, []);

  return (
    <Layout pageTitle="Miter for Zoom" description="Thanks for installing Miter for Zoom.">
      <SimpleHeaderModule title="Thanks for Installing!">Redirecting...</SimpleHeaderModule>
    </Layout>
  );
};
